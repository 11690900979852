import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 76,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Manrope, sans-serif",
    secondary: "Nyght Serif, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#5E94FB",
      light: "#3578EB",
      dark: "#020732",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#FFEB61",
      light: "#F9F9F9",
      dark: "#16233c",
    },
    tertiary: {
      main: "#F7F9FF",
      light: "#F2F6FF",
      dark: "#232323",
    },
    text: {
      primary: "#1D1D1F",
      secondary: "#191919",
      content: "#000000",
      tertiary: "#FFFDFA",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
      error: "#d32f2f",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Manrope", "Nyght Serif", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
